<template>
  <div
    onselectstart="return false"
    style="
      float: left;
      background: #353d47;
      color: #d3dce6;
      overflow: auto;
      height: 100%;
      border-right: solid 1px #e6e6e6;
    "
  >
    <el-menu
      class="el-menu-vertical-demo"
      :collapse="isCollapse"
      :router="true"
      unique-opened
      background-color="#353D47"
      text-color="#d3dce6"
      active-text-color="#ffd04b"
      @open="handleOpen"
      @close="handleClose"
      :default-active="this.$route.path"
    >
      <el-submenu
        v-for="node in routerList"
        v-if="node.children && node.children.length > 0"
        v-show="node.meta.isShow"
        :index="node.selectedPath"
        :key="node.meta.code"
      >
        <template slot="title">
          <i
            :class="node.meta.icon"
            v-if="node.meta.icon.indexOf('fa-') > -1"
            style="
              fontsize: 18px;
              margin-right: 5px;
              text-align: center;
              width: 24px;
              vertical-align: middle;
            "
          ></i>
          <i :class="node.meta.icon" v-else style="fontsize: 18px"></i>
          <!-- <span slot="title">123</span> -->
          <span slot="title">{{ node.name }}</span>
        </template>
        <el-menu-item-group>
          <span v-for="(nodechild, indexx) in node.children" :key="indexx">
            <template v-if="nodechild.meta.isShow">
              <el-submenu
                v-if="nodechild.children && nodechild.children.length > 0"
                :index="nodechild.selectedPath"
              >
                <template slot="title">
                  <span>{{ nodechild.name }}</span>
                </template>
                <el-menu-item-group>
                  <span v-for="(nodechildd, indexxx) in nodechild.children" :key="indexxx">
                    <el-menu-item
                      @click="openTabs(nodechildd)"
                      v-if="nodechildd.meta.isShow"
                      :index="nodechildd.selectedPath"
                    >{{ nodechildd.name }}</el-menu-item>
                  </span>
                </el-menu-item-group>
              </el-submenu>
              <el-menu-item
                @click="openTabs(nodechild)"
                :index="nodechild.selectedPath"
                v-else
              >{{ nodechild.name }}</el-menu-item>
            </template>
          </span>
        </el-menu-item-group>
      </el-submenu>
      <el-menu-item
        @click="openTabs(node)"
        v-else
        v-show="node.meta.isShow"
        :index="node.selectedPath"
      >
        <i :class="node.meta.icon" style="margin-right: 5px;margin-left:6px"></i>
        <span slot="title" style="margin-left:6px">{{ node.name }}</span>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script>

export default {
  name: "sidebar",
  data() {
    return {
      selectedPath: null
    };
  },
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    leafWidth: {
      type: String,
      default: ""
    },
    defaultIsOpen: {
      type: Boolean,
      default: false
    },
    routerList: {
      type: Array
    },
    value: {
      default: null
    },
    isCollapse: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.selectedPath = this.$route.fullPath;
  },
  methods: {
    openTabs(nodechild) {
      this.$emit("openTabs", nodechild);
    },
    handleOpen(key, keyPath) {
      // console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      // console.log(key, keyPath);
    },
    selectedNode(node) {
      this.selectedPath = node.selectedPath;
      this.$emit("change", node.selectedPath);
      this.$emit("node", node);
    }
  }
};
</script>

<style lang="scss">
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}
.el-menu {
  border-right: 0;

  .el-submenu .el-menu-item {
    background: #20252b !important;
  }
}

.el-submenu /deep/ .el-menu-item-group__title {
  padding: 0 !important;
}

.el-submenu /deep/ .el-menu-item.is-active,
/deep/ .is-active {
  background: #2f3337 !important;
}

.el-menu-item-group .el-submenu /deep/ .el-submenu__title {
  background: rgb(32, 37, 43) !important;
}
</style>
